type mediaScreen = {

  sm: number,
  md: number,
  lg: number,
  xl: number,

}

const media: mediaScreen = {

  sm: 459.98,
  md: 767.98,
  lg: 1199.98,
  xl: 1599.98,

}

export default media